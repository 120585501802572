export const resumeData = {
  main: {
    name: "Midteide Consulting AS",
    description:
      "I am a cybernetics engineer and a senior web developer with experience from prestiguous Norwegian fin-tech companies like DNB, Eika and Pareto bank",
    image: "profilepic.jpg",
    bio: "I am a senior Full-stack developer with experience from prestigious Norwegian fin-tech companies like DNB, Eika and Pareto bank. My main area of expertise is front-end, mainly focusing on React and related technologies. Been programming since I was ten. Cybernetics engineer with 13 years working experience as both SW developer and project manager. My friends and coworkers describe me as a social person that goes along and cooperates well with all types of people.",
    contactmessage:
      "Here is where you should write your message to readers to have them get in contact with you.",
    email: "alexander(a)midteideconsulting.no",
    phone: "93065250",
    // github: "https://github.com/nordicgiant2/react-nice-resume",
    // project: "https://github.com/nordicgiant2/react-nice-resume",
    address: {
      street: "Etterstadsletta 25",
      city: "Oslo",
      state: "Norway",
      zip: "0660",
    },
    // website: "http://www.timbakerdev.com",
    // resumedownload: "http://timbakerdev.com",
    social: [
      //   {
      //     name: "facebook",
      //     url: "https://github.com/nordicgiant2/react-nice-resume",
      //     className: "fa fa-facebook",
      //   },
      //   {
      //     name: "twitter",
      //     url: "http://twitter.com",
      //     className: "fa fa-twitter",
      //   },
      {
        name: "linkedin",
        url: "https://www.linkedin.com/in/alexander-midteide/",
        className: "fa fa-linkedin",
      },
      //   {
      //     name: "instagram",
      //     url: "http://instagram.com/tbaker_x",
      //     className: "fa fa-instagram",
      //   },
      //   {
      //     name: "github",
      //     url: "https://github.com/nordicgiant2/react-nice-resume",
      //     className: "fa fa-github",
      //   },
    ],
  },
  resume: {
    skillmessage:
      // "Senior Full-stack developer specializing in Front-end field, mainly focusing on React development. Been programming since I was ten. I'm a cybernetics engineer with 13 years working experience as engineer, SW developer and project manager. Others describe me as a social person that works well with all types of people.",
      "Highly skilled and experienced in React, typescript, CSS and a lot of related technologies. I love what I do, I work hard and I am team player that loves to get to know new people.",
    education: [
      {
        school: "OsloMet / Queensland University of Technology (QUT)",
        degree: "Cybernetics",
        graduated: "June 2009",
        description: "Programming, Assembly/C/C++/Java and control theory",
      },
      //   {
      //     school: "School #1 Maybe College?",
      //     degree: "What did you study 101",
      //     graduated: "March 2013",
      //     description:
      //       "Describe your experience at school, what you learned, what useful skills you have acquired etc.",
      //   },
    ],
    work: [
      {
        company: "Eika",
        title: "Senior Full stack developer",
        years: "November 2021 - Current",
        description:
          "Development of new web and mobile bank used by more than 50 banks in Norway with several hundred thousand users.",
      },
      {
        company: "DNB",
        title: "Full stack developer",
        years: "January 2020 - April 2021",
        description:
          "Development of completely new webpages for the most visited banking website in Norway (www.dnb.no). I also worked on new CRM tool for editors to use for creating content.",
      },
      {
        company: "Pareto Bank",
        title: "Senior Full stack developer",
        years: "April 2021 - August 2021",
        description: "Development of web bank.",
      },
      {
        company: "Allente",
        title: "Senior Full stack developer",
        years: "August 2021 - November 2021",
        description: "Development of new web pages.",
      },
    ],
    skills: [
      {
        name: "ReactJs",
        level: "80%",
      },
      {
        name: "Javascript",
        level: "80%",
      },
      {
        name: "Typescript",
        level: "80%",
      },
      {
        name: "Java",
        level: "60%",
      },
      {
        name: "Node.js",
        level: "60%",
      },
      {
        name: "Postgres",
        level: "70%",
      },
      {
        name: "SQL",
        level: "60%",
      },
    ],
  },
  portfolio: {
    projects: [
      {
        title: "DNB, New webpages",
        category: "Fintech",
        image: "01.jpg",
        url: "https://www.dnb.no",
      },
      {
        title: "Eika, New web/mobile bank",
        category: "Fintech",
        image: "02.jpg",
        url: "http://www.eika.no",
      },
      {
        title: "Pareto Bank, New webpages",
        category: "Fintech",
        image: "03.jpg",
        url: "https://www.paretobank.no/",
      },
      {
        title: "Allente, New webpages",
        category: "Broadcasting",
        image: "04.jpg",
        url: "http://www.allente.no",
      },
    ],
    private: [
      {
        title: "Crypto app React/Node/Postgres hosted on private linux server",
        category: "Private",
        image: "08.jpg",
        url: "http://crypto.midteide.com",
      },
      {
        title: "CabinControl Microcontroller circuit/SW",
        category: "Private",
        image: "05.jpg",
        url: "http://www.midteideconsulting.no",
      },
      {
        title: "Chess Clock Microcontroller circuit/SW",
        category: "Private",
        image: "06.jpg",
        url: "http://www.midteideconsulting.no",
      },
      {
        title: "Moisture sensor Microcontroller circuit/SW",
        category: "Private",
        image: "07.jpg",
        url: "http://www.midteideconsulting.no",
      },
    ],
  },
};
