import React, { Component } from "react";
import Zmage from "react-zmage";
import Fade from "react-reveal";

let id = 0;
const Portfolio = (props) => {
  console.log("Props: ", props.data["projects"]);
  if (!props.data) return null;

  const projects = (dataName) =>
    props.data[dataName].map((project) => {
      let projectImage = "images/portfolio/" + project.image;
      console.log("Mapping");
      return (
        <div key={id++} className="columns portfolio-item">
          <div className="item-wrap">
            <Zmage alt={project.title} src={projectImage} />
            <div style={{ textAlign: "center" }}>{project.title}</div>
          </div>
        </div>
      );
    });

  return (
    <section id="portfolio">
      <Fade left duration={1000} distance="40px">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>Check Out Some of My Professional Work:</h1>

            <div
              id="portfolio-wrapper"
              className="bgrid-quarters s-bgrid-thirds cf"
            >
              {projects("projects")}
            </div>
          </div>
        </div>
      </Fade>
      <Fade left duration={1000} distance="40px">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>...And Some Private Projects:</h1>

            <div
              id="portfolio-wrapper"
              className="bgrid-quarters s-bgrid-thirds cf"
            >
              {projects("private")}
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default Portfolio;
